const Config = require('../config/config.json');

export default {
	/**
	 * url处理
	 * @param url
	 */
	url(url: string) {
		const requestUrl = Config.hostUrl + url;
		return requestUrl;
	},
	/**
	 * 请求前对发送的数据进处理
	 * @param data 
	 */
	data(data: any) {
		const token = this.getCookie(Config.tokenName);
		if (token == '') {
			window.location.href = '/';
		}
		data.token = token;
		return data;
	},
	/**
	 * 设置cookie
	 * @param cname 
	 * @param cvalue 
	 * @param time 
	 */
	setCookie(cname: string, cvalue: string, time: number) {
		var d = new Date();
		d.setTime(time);
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

	},
	/**
	 * 获取cookie
	 * @param cname 
	 */
	getCookie(cname: string) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(";");
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";

	},
	/**
	 * 请求成功对返回数据的处理
	 * @param result 
	 */
	success(result: any) {
		if (result.result) {
			return result;
		} else {
			return { result: false,code:0 , msg: result.msg };
		}
	},
	/**
	 * 请求失败处理
	 * @param error 
	 */
	error(error: any) {
		console.log(error);
		return { result: false,code:0 ,msg: "请求失败，请稍后再试" };
	},
	/**
	 * 检查是否登录
	 */
	checkLogin() {
		const token = this.getCookie(Config.tokenName);
		if (token == '') {
			return false;
		} else {
			return true;
		}
	},

	/**
	 * 获取项目管理系统Url
	 * 
	 * @returns 
	 */
	getProjectUrl(){
		return Config.projectUrl;
	},
}