import axios, { Method } from "axios";
import qs from 'qs';
interface requestConfig {
    url: string,
    data: any,
    method?: Method,
    async?: boolean,
    headers?: any,
    success?: Function,
    error?: Function,
}
export default {

    /**
     * post请求
     * 
     * @param config requestConfig
     */
    post: async (config: requestConfig) => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };
        if (config.headers != undefined) {
            headers = config.headers;
        }
        if (config.async == false) {
            let res = await axios({
                url: config.url,
                data: qs.stringify(config.data),
                method: 'post',
                timeout: 5000,
                headers: headers,
            }).then((success) => {
                if (typeof (config.success) == 'function') {
                    return config.success(success.data);
                }
            }).catch((error) => {
                if (typeof (config.error) == 'function') {
                    return config.error(error);
                }
            });
            return res;
        } else {
            axios({
                url: config.url,
                data: qs.stringify(config.data),
                method: 'post',
                timeout: 5000,
                headers: headers,
            }).then((success) => {
                if (typeof (config.success) == 'function') {
                    config.success(success.data);
                }
            }).catch((error) => {
                if (typeof (config.error) == 'function') {
                    config.error(error);
                }
            });
        }

    },

    /**
     * get请求
     * 
     * @param config requestConfig
     */
    get: async (config: requestConfig) => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };
        if (config.headers != undefined) {
            headers = config.headers;
        }
        if (config.async == false) {
            let res = await axios({
                url: config.url,
                params: config.data,
                method: 'get',
                timeout: 5000,
                headers: headers,
            }).then((success) => {
                if (typeof (config.success) == 'function') {
                    return config.success(success.data);
                }
            }).catch((error) => {
                if (typeof (config.error) == 'function') {
                    return config.error(error);
                }
            });
            return res;
        } else {
            axios({
                url: config.url,
                params: config.data,
                method: 'get',
                timeout: 5000,
                headers: headers,
            }).then((success) => {
                if (typeof (config.success) == 'function') {
                    config.success(success.data);
                }
            }).catch((error) => {
                if (typeof (config.error) == 'function') {
                    config.error(error);
                }
            });
        }
    },
}