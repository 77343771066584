import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'index',
		component: () => import('@/view/Index.vue')
		// component:resolve=>(require(["@/view/Index.vue"],resolve))
	},
	{
		path: '/webgltest',
		name: 'webgltest',
		// component: () => import('@/view/WebglTest.vue')
		component:resolve=>(require(["@/view/WebglTest.vue"],resolve))
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default router
