<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "App",
  mounted() {
    // 友盟统计添加
    const script = document.createElement("script");
    // script.src = "https://s9.cnzz.com/z_stat.php?id=1279889277&web_id=1279889277";
   script.src = "https://s11.cnzz.com/z_stat.php?id=1260281898&web_id=1260281898";
    script.language = "JavaScript";
    document.body.appendChild(script);
  },
  methods:{

  },
  watch: {
    $route() {
      if (window._czc) {
        let location = window.location;
        let contentUrl = location.pathname + location.hash;
        let refererUrl = "/";
        window._czc.push(["_trackPageview", contentUrl, refererUrl]);
      }
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
body {
  min-width: 720px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
button {
  cursor: pointer;
}

// 文章详情-通用样式
.article-info-content {
  width: 100%;
  min-height: 80vh;
  max-height: 80vh;
  overflow: auto;
  img {
    height: unset;
  }
  .title {
    text-align: center;
    line-height: 40px;
    color: #090909;
    font-size: 24px;
  }
  .time {
    text-align: center;
    line-height: 40px;
  }
}
</style>