import Middleware from './middleware';
import axios from './http.axios';
interface axiosConfig {
	data: any,
	url?: string,
	method?: string,
	chain?: boolean,
	success?: Function,
	error?: Function,
}
interface chainConfig {
	url: string,
	data: any,
	method?: string,
	middleware?: boolean,
}
interface callBlackConfig {
	url: string,
	data: any,
	method?: string,
	middleware?: boolean,
	success?: Function,
	error?: Function,
}

/**
 * 链式请求方法(公用)
 * 
 * @param config 
 * @returns 
 */
async function chain(config: chainConfig) {
	let { callBackRes }: any = {};
	if (config.method == 'get') {
		callBackRes = await axios.get({
			url: config.url,
			data: config.data,
			async: false,
			success: (result: any) => {
				if (config.middleware != false) {
					result = Middleware.success(result);
				}
				return result;
			},
			error: (error: any) => {
				let err = Middleware.error(error);
				return err;
			}
		});
	} else {
		callBackRes = await axios.post({
			url: config.url,
			data: config.data,
			async: false,
			success: (result: any) => {
				if (config.middleware != false) {
					result = Middleware.success(result);
				}
				return result;
			},
			error: (error: any) => {
				let err = Middleware.error(error);
				return err;
			}
		});
	}
	return new Promise((resolve) => {
		resolve(callBackRes);
	});
}

/**
 * 回调函数式请求方法(公用)
 * 
 * @param config 
 */
async function callBlack(config: callBlackConfig) {
	if (config.method == 'get') {
		axios.get({
			url: config.url,
			data: config.data,
			success: (result: any) => {
				if (config.middleware != false) {
					result = Middleware.success(result);
				}
				if (typeof (config.success) == 'function') {
					config.success(result);
				}
			},
			error: (error: any) => {
				let err = Middleware.error(error);
				if (typeof (config.error) == 'function') {
					config.error(err);
				}
			}
		});
	} else {
		axios.post({
			url: config.url,
			data: config.data,
			success: (result: any) => {
				if (config.middleware != false) {
					result = Middleware.success(result);
				}
				if (typeof (config.success) == 'function') {
					config.success(result);
				}
			},
			error: (error: any) => {
				let err = Middleware.error(error);
				if (typeof (config.error) == 'function') {
					config.error(err);
				}
			}
		});
	}
}

/**
 * 检查请求方法
 * 
 * @param methond 
 * @returns 
 */
function checkMethond(methond: any) {
	if (methond == undefined) {
		methond = "post";
	}
	return methond;
}
const request = {

	/**
	 * 公用请求方法(不会对url和data进行任何处理,也不会对200状态码的结果进行处理)
	 * 
	 * @param config 
	 * @returns 
	 */
	async request(config: axiosConfig) {
		let { requestUrl }: any = config.url;
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			middleware: false,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},

	/**
	 * 人员详情
	 * 
	 * @param config 
	 * @returns 
	 */
	async personnelInfo(config: axiosConfig) {
		let url = "/api/personnel/info";
		let requestUrl = Middleware.url(url);
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},

	/**
	 * 思考文章列表
	 * 
	 * @param config 
	 * @returns 
	 */
	async thinkList(config: axiosConfig) {
		let url = "/api/think/list";
		let requestUrl = Middleware.url(url);
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},

	/**
	 * 思考文章详情
	 * 
	 * @param config 
	 * @returns 
	 */
	async thinkInfo(config: axiosConfig) {
		let url = "/api/think/info";
		let requestUrl = Middleware.url(url);
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},

	/**
	 * 项目文章列表
	 * 
	 * @param config 
	 * @returns 
	 */
	async projectList(config: axiosConfig) {
		let url = "/api/project/list";
		let requestUrl = Middleware.url(url);
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},

	/**
	 * 项目文章详情
	 * 
	 * @param config 
	 * @returns 
	 */
	async projectInfo(config: axiosConfig) {
		let url = "/api/project/info";
		let requestUrl = Middleware.url(url);
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},

	/**
	 * 关于我们文章列表
	 * 
	 * @param config 
	 * @returns 
	 */
	async aboutsList(config: axiosConfig) {
		let url = "/api/abouts/list";
		let requestUrl = Middleware.url(url);
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},

	/**
	 * 关于我们文章详情
	 * 
	 * @param config 
	 * @returns 
	 */
	async aboutsInfo(config: axiosConfig) {
		let url = "/api/abouts/info";
		let requestUrl = Middleware.url(url);
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},

	/**
	 * 活动列表
	 * 
	 * @param config 
	 * @returns 
	 */
	async activityList(config: axiosConfig) {
		let url = "/api/activity/list";
		let requestUrl = Middleware.url(url);
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},

	/**
	 * 活动详情
	 * 
	 * @param config 
	 * @returns 
	 */
	async activityInfo(config: axiosConfig) {
		let url = "/api/activity/info";
		let requestUrl = Middleware.url(url);
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},

	/**
	 * 获取萤石云token
	 * 
	 * @param config 
	 * @returns 
	 */
	async getLappToken(config: axiosConfig) {
		let url = "/api/ys7/getLappToken";
		let requestUrl = Middleware.url(url);
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},

	/**
	 * 雅恒/获取项目
	 * 
	 * @param config 
	 * @returns 
	 */
	async blockProject(config: axiosConfig) {
		let url = Middleware.getProjectUrl();
		let requestUrl = url;
		config.data.m = "yaheen";
		config.data.f = "blockProject";
		config.data.t = "json";
		let method = checkMethond(config.method);
		let reqConfig = {
			url: requestUrl,
			data: config.data,
			method: method,
			success: config.success,
			error: config.error,
		}
		if (config.chain == true) {
			return new Promise((resolve) => {
				resolve(chain(reqConfig));
			});
		} else {
			callBlack(reqConfig);
		}
	},
}

export default request;