import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import lazyPlugin from 'vue3-lazy'

const app = createApp(App)
declare global {
  interface Window {
    Hls: any
    Flv: any
  }
}
window.Hls = require('hls.js')
window.Flv = require('flv.js')


app.use(router)
app.use(ElementPlus)
// app.use(lazyPlugin)
// app.use(lazyPlugin, {
//     loading: require('@/assets/images/default.png'), // 图片加载时默认图片
//     error: require('@/assets/images/error.png')// 图片加载失败时默认图片
//   })
  // .mount('#app')
// app.use(directive)
//添加自定义的全局公用函数
import comm from "@/common/comm"
import time from "@/common/time"
import request from "@/services/request"
app.config.globalProperties.$comm = comm
app.config.globalProperties.$time = time
app.config.globalProperties.$request = request
// app.config.globalProperties.$VueLazyLoad = VueLazyLoad

router.isReady().then(() => {
  app.mount('#app')
})