export default {
	/**
	 * 获取年分
	 * 
	 * @param date 
	 * @returns 
	 */
	getYear(date: number) {
		var thisDate = new Date(date);
		var year = thisDate.getFullYear();
		return year;
	},
	/**
	 * 获取日期
	 * @param date 
	 * @returns 
	 */
	getYmdHis(date: number) {
		var thisDate = new Date(date);
		var Y = thisDate.getFullYear();
		var m = this.formatZero(thisDate.getMonth() + 1, 2);
		var d = this.formatZero(thisDate.getDate(), 2);
		var H = this.formatZero(thisDate.getHours(), 2);
		var i = this.formatZero(thisDate.getMinutes(), 2);
		var s = this.formatZero(thisDate.getSeconds(), 2);
		var YmdHis = Y + "-" + m + "-" + d + " " + H + ":" + i + ":" + s
		return YmdHis;
	},
	/**
	 * 不足位数前面补0
	 * @param {*} num 
	 * @param {*} len 
	 * @returns 
	 */
	formatZero(num: number, len: number) {
		if (String(num).length > len) return num;
		return (Array(len).join('0') + num).slice(-len);
	},
}